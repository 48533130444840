import React from "react";

import Footer from "./Footer";
import Header from "./Header";


const Layout = ({children}) => {


  return (
    <>
      <Header/>

      {children}

      <section className="section bg-primary">
        <Footer/>
      </section>

    </>
  );
};

export default Layout;
