import * as React from "react"
import "./src/styles/index.scss"

import Layout from "./src/components/Layout";

export function wrapPageElement({element}) {

  return (
    <Layout>
      {element}
    </Layout>
  );
}
