import React from "react";
import {StaticImage} from "gatsby-plugin-image";


const LeftTopNote = ({text, left = true}) => {


  return (
    <div className="top-note">
      <p>
        {text}
      </p>

      {
        left &&  <StaticImage src="../assets/images/line-circle-small.png" className="line-circle" alt=""/>
      }

      {
        !left && <StaticImage src="../assets/images/hero/hero-ornament.png" className="" alt=""/>
      }

    </div>
  );
};

export default LeftTopNote;
