import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import {useLocation} from '@reach/router';

import LeftTopNote from "./LeftTopNote";

const Header = () => {

  const [opened, setOpened] = useState(false);

  const [servicesOpened, setServicesOpened] = useState(false);

  useEffect(() => {
    document.body.style.overflow = opened ? "hidden" : "unset"
  }, [opened]);

  const location = useLocation();

  useEffect(() => {
    const setRealHeight = () => {
      if (window.innerWidth > 1100) {
        setOpened(false);
        setServicesOpened(false);
      }
    };
    window.addEventListener("resize", setRealHeight);
    return () => window.removeEventListener("resize", setRealHeight);
  });

  const onTreatmentClick = (ev) => {
    if (opened) {
      ev.preventDefault();
      setServicesOpened(true);
      return;
    }
  }

  useEffect(() => {
    setOpened(false);
    // setServicesOpened(false);
  }, [location]);

  return (
    <header className={"header-component" + (opened ? ' opened' : '')}>

      <div className="container header-wrapper">

        {/* Header */}
        <Link to="/" className="logo-img">
          <StaticImage src="../assets/images/logo-gold.png" alt=""/>
        </Link>

        {/* Header mobile */}
        <div className="header-mobile">

          {/* Phone button */}
          <button className="button" role="button" aria-label="Phone">
            <a href="tel:+381641244747" aria-label="Link for a phone">
              <StaticImage src="../assets/images/icons/phone-icon.png" className="phone-img" alt=""/>
            </a>
          </button>

          {/* Mobile logo small */}
          <Link to="/" aria-label="Home link">
            <StaticImage src="../assets/images/logo-gold-small.png" width={70} className="logo-img-small" alt=""/>
          </Link>

          {/* Toggle menu open */}
          <button className="button margin-left-auto" aria-label="Toogle menu bar">
            {!opened && (
              <div onClick={() => setOpened(true)}>
                <StaticImage src="../assets/images/icons/hamburger-icon.png" className="hamburger-img " alt=""/>
              </div>
            )}
            {opened && !servicesOpened && (
              <div onClick={() => setOpened(false)}>
                <StaticImage src="../assets/images/icons/close-icon.png"
                             className="close-img" alt=""/>
              </div>
            )}
            {opened && servicesOpened && (
              <div onClick={() => setServicesOpened(false)}>
                <StaticImage src="../assets/images/icons/close-icon.png"
                             className="close-img" alt=""/>
              </div>
            )}
          </button>

        </div>

        {/* Note fact */}
        <div className="note-text">
          <LeftTopNote text="an introduction o 0 f a new hair style product"></LeftTopNote>
        </div>

        {
          servicesOpened && <div className="mobile-services">

            <ul role="list" className="nav-links">
              <li className="nav-item">
                <Link className="nav-link" to="/tretmani/tretmani-i-nega-lica" activeClassName="active">Tretmani
                  lica</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tretmani/manikir-pedikir" activeClassName="active">Manikir i
                  pedikir</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tretmani/depilacija" activeClassName="active">Depilacija</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tretmani/laserska-epilacija" activeClassName="active">>> Laserska
                  epilacija (NOVO) &lt;&lt;</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tretmani/profesionalna-sminka" activeClassName="active">Profesionalna
                  šminka</Link>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <Link className="nav-link" to="/tretmani/laminacija-obrva-i-trepavica" activeClassName="active">Laminacija obrva</Link>*/}
              {/*</li>*/}
              <li className="nav-item">
                <Link className="nav-link" to="/tretmani/masaza" activeClassName="active">Masaža</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tretmani/lux-spa-tretman" activeClassName="active">Lux Spa tretman</Link>
              </li>
            </ul>

          </div>
        }

        {/* Header navigation */}
        <nav className="primary-navigation">

          <ul role="list" className="nav-links">
            <li className="nav-item">
              <Link className="nav-link" to="/" activeClassName="active">Početna</Link>
            </li>
            <li className="nav-item">
              <Link onClick={(ev) => onTreatmentClick(ev)} className="nav-link" to="/tretmani" partiallyActive={true}
                    activeClassName="active">Tretmani</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/cenovnik" partiallyActive={true}
                    activeClassName="active">Cenovnik</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link star" to="/#akcije" partiallyActive={true}
                    activeClassName="active">Akcije</Link>
            </li>
            {/*<li className="nav-item">*/}
            {/*  <Link className="nav-link" to="/tretmani" partiallyActive={true} activeClassName="active">Cenovnik</Link>*/}
            {/*</li>*/}
            <li className="nav-item">
              <Link className="nav-link star" to="/poklon-vauceri" partiallyActive={true} activeClassName="active">Poklon
                vaučeri</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/o-beauty-salonu" partiallyActive={true} activeClassName="active">O
                nama</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/kontakt-i-kako-do-nas" partiallyActive={true}
                    activeClassName="active">Kontakt</Link>
            </li>
          </ul>

        </nav>

        <div className="social-links">

          <a href="https://www.instagram.com/beauty.ambassade.salon/" className="social-link" target="_blank"
             aria-label="Instagram link">
            <StaticImage src="../assets/images/icons/instagram-logo-fill.png" alt=""/>
          </a>
          <a href="https://maps.app.goo.gl/dpqbwxqnBttdakrYA" className="social-link" target="_blank"
             aria-label="Facebook link">
            <StaticImage src="../assets/images/icons/facebook-logo-fill.png" alt=""/>
          </a>
        </div>

        {/* Button CTA */}
        <button className="button button-primary cta-action margin-left-auto">
          <a href="tel:+381641244747">Pozovite nas</a>
        </button>

        {/* Copyright text */}
        <p className="copyright-text"><a
          className="text-underline"
          href="https://maps.app.goo.gl/dpqbwxqnBttdakrYA" target="_blank">Bulevar Vudroa Vilsona 10, Beograd na
          vodi</a></p>
      </div>

    </header>
  );
};

export default Header;
